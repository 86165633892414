import api from './../services/api';

import { closeModal } from './modal';

import moment from 'moment';
import 'moment/locale/es';
import _ from 'lodash';

export const SETTABPETITION = 'petition/SETTAB';
export const SETVIEWTYPE = 'petition/SETVIEWTYPE';
export const SETPETITIONS = 'petition/SETPETITIONS';
export const SETBLOCKSLOT = 'petition/SETBLOCKSLOT';
export const GETGROUPTYPES = 'petition/GETGROUPTYPES';
export const GETVISITLANGUAGES = 'petition/GETVISITLANGUAGES';

const initialState = {
  tab: undefined,
  firstTimePetitions: false,
  petitions: undefined,
  blockSlots: undefined,
  nextPage: undefined,
  groupTypes: undefined,
  visitLanguages: undefined,
  webLanguages: [
    { id: 'es', name: 'Español' },
    { id: 'en', name: 'Inglés' },
  ],
  genders: [
    { id: 'male', name: 'Sr.' },
    { id: 'female', name: 'Sra.' },
  ],
  viewType: 'month',
};

const storeModule = (state = initialState, action) => {
  switch (action.type) {
    case SETTABPETITION:
      return {
        ...state,
        tab: action.playload,
        firstTimePetitions: true,
        petitions: initialState.petitions,
      };
    case SETPETITIONS:
      return {
        ...state,
        firstTimePetitions: false,
        petitions: _.union(state.petitions, action.playload.petitions),
        nextPage: action.playload.nextPage,
      };
    case SETVIEWTYPE:
      return {
        ...state,
        viewType: action.playload,
      };
    case SETBLOCKSLOT:
      return {
        ...state,
        blockSlots: action.playload,
      };
    case GETGROUPTYPES:
      return {
        ...state,
        groupTypes: action.playload,
      };
    case GETVISITLANGUAGES:
      return {
        ...state,
        visitLanguages: action.playload,
      };
    default:
      return state;
  }
};
export default storeModule;

export const getGroupTypes = () => {
  return (dispatch, getState) => {
    api.get(dispatch, 'es/group-types').then(r => {
      dispatch({
        type: GETGROUPTYPES,
        playload: r,
      });
    });
  };
};

export const setTab = (tab = undefined) => {
  return (dispatch, getState) => {
    dispatch({
      type: SETTABPETITION,
      playload: tab ? tab : getState().petitions.tab,
    });
    getPetitions()(dispatch, getState);
  };
};

export const getPetitions = (page = undefined) => {
  return (dispatch, getState) => {
    let { path } = getState().petitions.tab;

    let params = {};
    //if(getState().petitions.tab.calendar === false) params.limit = 1;
    if (page) params.page = page;

    api.get(dispatch, path, undefined, params).then(r => {
      dispatch({
        type: SETPETITIONS,
        playload: {
          petitions: r.data ? r.data : r,
          nextPage:
            r.current_page < r.last_page ? r.current_page + 1 : undefined,
        },
      });
    });
  };
};

export const approvePetition = (params = undefined) => {
  return (dispatch, getState) => {
    api.post(dispatch, 'approved-petitions', params).then(r => {
      setTab()(dispatch, getState);
      closeModal()(dispatch);
    });
  };
};

export const denyPetition = (id, params) => {
  return (dispatch, getState) => {
    api.post(dispatch, `reject-petition/${id}`, params).then(r => {
      setTab()(dispatch, getState);
      closeModal()(dispatch);
    });
  };
};

export const createPetition = (params = undefined) => {
  return (dispatch, getState) => {
    api.post(dispatch, 'new-petition', params).then(r => {
      setTab()(dispatch, getState);
      closeModal()(dispatch);
    });
  };
};

export const editPetition = (id, params = undefined) => {
  return (dispatch, getState) => {
    api.put(dispatch, 'petitions', id, params).then(r => {
      setTab()(dispatch, getState);
      closeModal()(dispatch);
    });
  };
};

export const deletePetition = id => {
  return (dispatch, getState) => {
    api.delete(dispatch, `future-petitions/${id}`).then(r => {
      setTab()(dispatch, getState);
      closeModal()(dispatch);
    });
  };
};

export const getCreatedAt = petition => {
  let created_at = moment(petition.created_at).format('DD-MM-YYYY');
  if (moment().format('DD-MM-YYYY') === created_at) {
    created_at = moment(petition.created_at).format('[Hoy a las ] HH:mm');
  } else if (
    moment()
      .add(-1, 'days')
      .format('DD-MM-YYYY') === moment(petition.created_at).format('DD-MM-YYYY')
  ) {
    created_at = moment(petition.created_at).format('[Ayer a las ] HH:mm');
  }
  return dispatch => created_at;
};

export const getVisitLanguages = () => {
  return dispatch => {
    api.get(dispatch, 'es/languages').then(r => {
      dispatch({
        type: GETVISITLANGUAGES,
        playload: r,
      });
    });
  };
};

export const getVisitLanguage = id => {
  return (dispatch, getState) => {
    return getState().petitions.visitLanguages?.find(l => l.id === id)?.name;
  };
};

export const setViewType = viewType => {
  return (dispatch, getState) => {
    dispatch({
      type: SETVIEWTYPE,
      playload: viewType,
    });
  };
};

export const getBlockSlots = () => {
  return dispatch => {
    api.get(dispatch, 'block-slot').then(r => {
      dispatch({
        type: SETBLOCKSLOT,
        playload: r,
      });
    });
  };
};

export const createBlockSlot = (params = undefined) => {
  return dispatch => {
    api.post(dispatch, 'block-slot', params).then(r => {
      getBlockSlots()(dispatch);
      closeModal()(dispatch);
    });
  };
};

export const deleteBlockSlot = id => {
  return dispatch => {
    api.delete(dispatch, `block-slot/${id}`).then(r => {
      getBlockSlots()(dispatch);
      closeModal()(dispatch);
    });
  };
};

export const exportPetitions = (type, filters = {}) => {
  return dispatch => {
    api
      .get(dispatch, type, 'excel', filters, {
        responseType: 'blob',
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${type}.xlsx`);

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
      })
      .catch(error => {
        console.error('Error al descargar el archivo:', error);
      });
  };
};

export const downloadPdf = ref => {
  return dispatch => {
    api
      .get(
        dispatch,
        'petitions',
        `${ref}.pdf`,
        {},
        {
          responseType: 'blob',
        },
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${ref}.pdf`);

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
      })
      .catch(error => {
        console.error('Error al descargar el archivo:', error);
      });
  };
};

import api from './../services/api';

import { closeModal } from './modal';

export const SETTAB = 'calendars/SETTAB';
export const SETCALENDAR = 'calendars/SETCALENDAR';

const initialState = {
  tab: undefined,
  hours: undefined,
  days: undefined,
};

const storeModule = (state = initialState, action) => {
  switch (action.type) {
    case SETTAB:
      return {
        ...state,
        tab: action.playload,
      };
    case SETCALENDAR:
      return {
        ...state,
        hours: action.playload.hours,
        days: action.playload.days,
      };
    default:
      return state;
  }
};
export default storeModule;

export const setTab = (tab = undefined) => {
  return (dispatch, getState) => {
    let playload = tab === undefined ? getState().calendars.tab : tab;
    dispatch({
      type: SETTAB,
      playload,
    });
    getCalendarInfo()(dispatch, getState);
  };
};

export const getCalendarInfo = () => {
  return (dispatch, getState) => {
    api.get(dispatch, 'es/buildings', getState().calendars.tab.id).then(r => {
      dispatch({
        type: SETCALENDAR,
        playload: { hours: r.hours, days: r.days },
      });
    });
  };
};

export const setCalendar = (id, params = undefined) => {
  return (dispatch, getState) => {
    api.put(dispatch, 'es/buildings', id, params).then(r => {
      getCalendarInfo()(dispatch, getState);
      closeModal()(dispatch);
    });
  };
};

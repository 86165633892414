import api from './../services/api';

import _ from 'lodash';

export const SETPUBLISH = 'publish/SETPUBLISH';
export const SETLASTENVIROMENT = 'publish/SETLASTENVIROMENT';
export const SETPHRASE = 'publish/SETPHRASE';

const initialState = {
  isPublishing: false,
  phrase: false,
  lastEnviroment: false,
  enviroments: [
    { id: 'prev', title: 'preview' },
    { id: 'prod', title: 'producción' },
  ],
};

const storeModule = (state = initialState, action) => {
  switch (action.type) {
    case SETPUBLISH:
      return {
        ...state,
        isPublishing: action.playload,
      };
    case SETLASTENVIROMENT:
      return {
        ...state,
        lastEnviroment: action.playload,
      };
    case SETPHRASE:
      return {
        ...state,
        phrase: action.playload,
      };
    default:
      return state;
  }
};
export default storeModule;

export const setPublish = (enviroment = undefined) => {
  return (dispatch, getState) => {
    let playload = enviroment;
    dispatch({
      type: SETPUBLISH,
      playload,
    });
    dispatch({
      type: SETLASTENVIROMENT,
      playload,
    });
    api.publish(dispatch, playload).then(() => {
      dispatch({
        type: SETPHRASE,
        playload: true,
      });
      return setTimeout(() => {
        dispatch({
          type: SETPHRASE,
          playload: undefined,
        });
        dispatch({
          type: SETPUBLISH,
          playload: false,
        });
      }, 5000);
    });
  };
};
export const getEnviroment = id => {
  let enviroment = _.find(initialState.enviroments, l => (l.id = id));
  if (enviroment) {
    return dispatch => enviroment.title;
  } else {
    return dispatch => id;
  }
};

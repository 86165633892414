import { combineReducers } from 'redux';

import modal from './modal';
import loader from './loader';
import login from './login';
import user from './user';
import petitions from './petitions';
import calendars from './calendars';
import groups from './groups';
import buildings from './buildings';
import faqs from './faqs';
import images from './images';
import reasons from './reasons';
import groupTypes from './group-types';
import publish from './publish';

const store = combineReducers({
  modal,
  loader,
  login,
  user,
  petitions,
  calendars,
  groups,
  buildings,
  faqs,
  images,
  reasons,
  groupTypes,
  publish,
});
export default store;

import api from './../services/api';

var Cookies = require('js-cookie');

const initialState = {};

const storeModule = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
export default storeModule;

export const setLogin = formData => {
  return dispatch => {
    formData.append('grant_type', 'password');
    formData.append('client_id', 1);
    formData.append(
      'client_secret',
      '9RdIvQjpFNn2siLl2freezezWjw1iHbsY6rlXSp6,',
    );
    api.oauth(dispatch, formData).then(r => {
      Cookies.set('access_token', r.access_token, {
        expires: r.expires_in / 86400,
      });
      window.location.href = '/';
    });
  };
};

export const getLogin = () => {
  return Cookies.get('access_token');
};

export const logout = () => {
  return dispatch => {
    Cookies.remove('access_token');
    window.location.href = '/login';
  };
};

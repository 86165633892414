exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-buildings-jsx": () => import("./../../../src/pages/buildings.jsx" /* webpackChunkName: "component---src-pages-buildings-jsx" */),
  "component---src-pages-calendars-jsx": () => import("./../../../src/pages/calendars.jsx" /* webpackChunkName: "component---src-pages-calendars-jsx" */),
  "component---src-pages-faqs-jsx": () => import("./../../../src/pages/faqs.jsx" /* webpackChunkName: "component---src-pages-faqs-jsx" */),
  "component---src-pages-group-types-jsx": () => import("./../../../src/pages/group-types.jsx" /* webpackChunkName: "component---src-pages-group-types-jsx" */),
  "component---src-pages-groups-jsx": () => import("./../../../src/pages/groups.jsx" /* webpackChunkName: "component---src-pages-groups-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-reasons-jsx": () => import("./../../../src/pages/reasons.jsx" /* webpackChunkName: "component---src-pages-reasons-jsx" */),
  "component---src-pages-users-jsx": () => import("./../../../src/pages/users.jsx" /* webpackChunkName: "component---src-pages-users-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */)
}


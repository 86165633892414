import api from '../services/api';

import { closeModal } from './modal';

const type = 'group-type';

export const SETELEMENTSES = type + '/SETELEMENTSES';
export const SETELEMENTSEN = type + '/SETELEMENTSEN';

const initialState = {
  elements_es: undefined,
  elements_en: undefined,
};

const storeModule = (state = initialState, action) => {
  switch (action.type) {
    case SETELEMENTSES:
      return {
        ...state,
        elements_es: action.playload,
      };
    case SETELEMENTSEN:
      return {
        ...state,
        elements_en: action.playload,
      };
    default:
      return state;
  }
};
export default storeModule;

export const getElements = () => {
  return dispatch => {
    api.get(dispatch, 'es/' + type + 's').then(r_es => {
      api.get(dispatch, 'en/' + type + 's').then(r_en => {
        dispatch({
          type: SETELEMENTSES,
          playload: r_es.data ? r_es.data : r_es,
        });
        dispatch({
          type: SETELEMENTSEN,
          playload: r_en.data ? r_en.data : r_en,
        });
      });
    });
  };
};

export const createElement = (params = undefined) => {
  return (dispatch, getState) => {
    api.post(dispatch, 'es/' + type + 's', params.formEs).then(r => {
      api.put(dispatch, 'en/' + type + 's', r.id, params.formEn).then(r => {
        getElements()(dispatch);
        closeModal()(dispatch);
      });
    });
  };
};

export const deleteElement = id => {
  return (dispatch, getState) => {
    api.delete(dispatch, 'es/' + type + 's', id).then(r => {
      getElements()(dispatch);
      closeModal()(dispatch);
    });
  };
};

export const setElement = (id, params = undefined) => {
  return (dispatch, getState) => {
    api.put(dispatch, 'es/' + type + 's', id, params.formEs).then(r => {
      if (params.formEn) {
        api.put(dispatch, 'en/' + type + 's', id, params.formEn).then(r => {
          getElements()(dispatch);
          closeModal()(dispatch);
        });
      } else {
        getElements()(dispatch);
        closeModal()(dispatch);
      }
    });
  };
};

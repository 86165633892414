import axios from 'axios';
import _ from 'lodash';
import Q from 'q';

import { hideLoader, showLoader } from '../modules/loader';
import { getLogin } from '../modules/login';
import { setError } from '../modules/modal';

const urlBase = `${process.env.GATSBY_API_URL}/`;
const defaultParams = {
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
    'x-api-key': process.env.GATSBY_X_API_KEY,
  },
  params: {},
};

const API = {
  get: (
    dispatch,
    call,
    urlParams = undefined,
    getParams = {},
    options = {},
  ) => {
    showLoader()(dispatch);
    var deferred = Q.defer();

    let url = urlBase + 'api/' + call;

    let defParams = _.clone(defaultParams);

    if (getLogin()) {
      defParams.headers['Authorization'] = 'Bearer ' + getLogin();
    }
    if (!_.isUndefined(urlParams)) {
      url += '/' + (_.isArray(urlParams) ? urlParams.join('/') : urlParams);
    }

    axios
      .get(url, _.merge(defParams, options, { params: { ...getParams } }))
      .then(response => {
        hideLoader()(dispatch);
        deferred.resolve(response.data);
      })
      .catch(error => {
        hideLoader()(dispatch);
        console.log('API GET ERROR: ', error);
        if (error.response?.status === 401) {
          window.location.href = '/login';
        } else {
          deferred.reject(error);
        }
      });
    return deferred.promise;
  },
  post: (dispatch, call, postParams = {}) => {
    showLoader()(dispatch);
    var deferred = Q.defer();

    let url = urlBase + 'api/' + call;

    let defParams = _.clone(defaultParams);
    if (getLogin()) {
      defParams.headers['Authorization'] = 'Bearer ' + getLogin();
    }

    axios
      .post(url, postParams, defParams)
      .then(response => {
        hideLoader()(dispatch);
        deferred.resolve(response.data);
      })
      .catch(error => {
        hideLoader()(dispatch);
        console.log('API POST ERROR: ', error);
        if (error.response?.status === 401) {
          window.location.href = '/login';
        } else {
          deferred.reject(error);
        }
      });
    return deferred.promise;
  },
  put: (dispatch, call, urlParams = undefined, postParams = {}) => {
    showLoader()(dispatch);
    var deferred = Q.defer();

    let url = urlBase + 'api/' + call;

    let defParams = _.clone(defaultParams);
    if (getLogin()) {
      defParams.headers['Authorization'] = 'Bearer ' + getLogin();
    }

    if (!_.isUndefined(urlParams)) {
      url += '/' + (_.isArray(urlParams) ? urlParams.join('/') : urlParams);
    }

    postParams.append('_method', 'put');

    axios
      .post(url, postParams, defParams)
      .then(response => {
        hideLoader()(dispatch);
        deferred.resolve(response.data);
      })
      .catch(error => {
        hideLoader()(dispatch);
        console.log('API PUT ERROR: ', error);
        if (error.response?.status === 401) {
          window.location.href = '/login';
        } else {
          deferred.reject(error);
        }
      });
    return deferred.promise;
  },
  delete: (dispatch, call, urlParams = undefined, getParams = {}) => {
    showLoader()(dispatch);
    var deferred = Q.defer();

    let url = urlBase + 'api/' + call;

    let defParams = _.clone(defaultParams);

    if (getLogin()) {
      defParams.headers['Authorization'] = 'Bearer ' + getLogin();
    }
    if (!_.isUndefined(urlParams)) {
      url += '/';
      url += _.isArray(urlParams) ? urlParams.join('/') : urlParams;
    }

    axios
      .delete(url, {
        ...defParams,
        params: getParams,
      })
      .then(response => {
        hideLoader()(dispatch);
        deferred.resolve(true);
      })
      .catch(error => {
        hideLoader()(dispatch);
        console.log('API DELETE ERROR: ', error);
        if (error.response?.status === 401) {
          window.location.href = '/login';
        } else if (error.response?.status === 400) {
          alert(error.response.data.message);
        } else {
          deferred.reject(error);
        }
      });
    return deferred.promise;
  },
  oauth: (dispatch, postParams = {}) => {
    showLoader()(dispatch);
    var deferred = Q.defer();

    let url = urlBase + 'api/oauth/token';

    axios.post(url, postParams, defaultParams).then(
      response => {
        hideLoader()(dispatch);
        deferred.resolve(response.data);
      },
      error => {
        hideLoader()(dispatch);
        switch (error.response.status) {
          case 401:
            setError('Credenciales no válidas.')(dispatch);
            break;
          case 500:
            setError('Demasiados intentos, espere en unos minutos.')(dispatch);
            break;
          default:
            console.log('API OAUTH ERROR', error);
        }
        deferred.reject(error);
      },
    );
    return deferred.promise;
  },
  publish: (dispatch, type = 'production') => {
    var deferred = Q.defer();
    let url = `${urlBase}api/codebuild/frontoffice/${type}`;

    axios
      .post(url, {}, defaultParams)
      .then(response => {
        if (response.status === 201) {
          deferred.resolve(response.data);
        } else {
          deferred.reject(response);
        }
      })
      .catch(error => {
        console.log('API PUBLISH ERROR', error);
        deferred.reject(error);
      });
    return deferred.promise;
  },
};
export default API;

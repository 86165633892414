import api from './../services/api';

import { closeModal } from './modal';

export const GETUSERS = 'user/GETUSERS';
export const GETSELF = 'user/GETSELF';
export const GETROLES = 'user/GETROLES';

const initialState = {
  users: undefined,
  self: undefined,
  roles: undefined,
};

const storeModule = (state = initialState, action) => {
  switch (action.type) {
    case GETUSERS:
      return {
        ...state,
        users: action.playload,
      };
    case GETSELF:
      return {
        ...state,
        self: action.playload,
      };
    case GETROLES:
      return {
        ...state,
        roles: action.playload,
      };
    default:
      return state;
  }
};
export default storeModule;

export const getUsers = () => {
  return dispatch => {
    api.get(dispatch, 'users').then(r => {
      dispatch({
        type: GETUSERS,
        playload: r,
      });
    });
  };
};

export const createUser = (user = undefined) => {
  return dispatch => {
    api.post(dispatch, 'users', user).then(r => {
      getUsers()(dispatch);
      closeModal()(dispatch);
    });
  };
};

export const changeRole = (id = undefined, params = undefined) => {
  return dispatch => {
    api.put(dispatch, 'users', id, params).then(r => {
      getUsers()(dispatch);
      closeModal()(dispatch);
    });
  };
};

export const deleteUser = id => {
  return dispatch => {
    api.delete(dispatch, 'users', id).then(r => {
      getUsers()(dispatch);
      closeModal()(dispatch);
    });
  };
};

export const getRoles = () => {
  return dispatch => {
    api.get(dispatch, 'users/roles').then(r => {
      dispatch({
        type: GETROLES,
        playload: r,
      });
    });
  };
};

export const regeneratePassword = formData => {
  return dispatch => {
    api
      .post(dispatch, 'change-password', { email: formData.getAll('email')[0] })
      .then(r => {
        closeModal()(dispatch);
      });
  };
};

export const getSelf = () => {
  return dispatch => {
    api.get(dispatch, 'my-self').then(r => {
      dispatch({
        type: GETSELF,
        playload: r,
      });
    });
  };
};

export const getRole = role => {
  switch (role) {
    case 'admin':
      return dispatch => 'Administrador';
    case 'factory':
      return dispatch => 'Fábrica';
    case 'communications':
      return dispatch => 'Comunicación';
    case 'agency':
      return dispatch => 'Agencia';
    default:
      return dispatch => role;
  }
};

export const SETERROR = 'modal/SETERROR';
export const SETTEMPLATE = 'modal/SETTEMPLATE';
export const SHOW = 'modal/SHOW';
export const HIDE = 'modal/HIDE';
export const DESTROY = 'modal/DESTROY';

const initialState = {
  errorMessage: false,
  show: false,
  empty: true,
  withAnimation: true,
  template: undefined,
};

const storeModule = (state = initialState, action) => {
  switch (action.type) {
    case SETERROR:
      return {
        ...state,
        errorMessage: action.playload,
        empty: false,
      };
    case SETTEMPLATE:
      return {
        ...state,
        template: action.playload,
        empty: false,
      };
    case SHOW:
      document.body.classList.add('modal-open');
      return {
        ...state,
        show: true,
      };
    case HIDE:
      return {
        ...state,
        show: false,
      };
    case DESTROY:
      document.body.classList.remove('modal-open');
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
export default storeModule;

export const setError = (string = null) => {
  const playload = string;
  return dispatch => {
    dispatch({
      type: SETERROR,
      playload,
    });

    return setTimeout(() => {
      dispatch({
        type: SHOW,
      });
    }, 100);
  };
};

export const setTemplate = (template = undefined, params = {}) => {
  const playload = { template, params };
  return dispatch => {
    dispatch({
      type: SETTEMPLATE,
      playload,
    });

    return setTimeout(() => {
      dispatch({
        type: SHOW,
      });
    }, 100);
  };
};

export const closeModal = () => {
  return dispatch => {
    dispatch({
      type: HIDE,
    });

    return setTimeout(() => {
      dispatch({
        type: DESTROY,
      });
    }, 300);
  };
};
